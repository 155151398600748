<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: "dark",
    },
    dismiss: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      variants: {
        success: "bg-green-100 text-green-700",
        danger: "bg-red-100 text-red-700",
        info: "bg-primary-100 text-primary-700 dark:bg-primary-800 dark:text-white",
        warning: "bg-yellow-100 text-yellow-700",
        dark: "bg-gray-100 text-gray-900",
      },
      icons: {
        success: "circle-check",
        danger: "circle-xmark",
        info: "circle-info",
        warning: "circle-info",
        dark: "circle-info",
      },
      show: true,
    };
  },
};
</script>

<template>
  <div v-if="show" class="relative rounded-md p-4" :class="[variants[variant]]">
    <div
      v-if="dismiss"
      class="absolute right-2 top-2 flex h-10 w-10 cursor-pointer items-center justify-center text-gray-900 hover:text-gray-700"
      @click="show = false"
    >
      <font-awesome-icon icon="times" class="mr-2 !h-[16px] !w-[16px]" />
    </div>
    <div v-if="title || icon" class="flex items-center">
      <font-awesome-icon
        v-if="icon"
        :icon="icons[variant]"
        class="mr-2 !h-[16px] !w-[16px]"
      />
      <div v-if="title" class="text-base font-semibold">
        {{ title }}
      </div>
    </div>
    <p v-if="$slots.default" class="mt-1 text-sm font-normal">
      <slot />
    </p>
    <div v-if="$slots.actions" class="mt-3 flex items-center gap-2">
      <slot name="actions" />
    </div>
  </div>
</template>
